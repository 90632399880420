<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import StoreService from '@/services/StoreService'

export default {
  name: 'ProductCard',

  props: {
    product: {
      type: Object,
      required: true
    },

    short: {
      type: Boolean,
      default: false
    },

    withCategoryText: {
      type: Boolean,
      default: true
    },

    withSellerText: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('marketplace', ['currencies']),
    ...mapGetters('user', ['isAuthorized']),

    locale () {
      return this.$i18n.locale?.split?.('-')[0] || 'en'
    },

    showRub () {
      return this.$i18n.locale === 'ru'
    },

    productId () {
      return this.product?.id
    },

    productCover () {
      return this.product?.images?.[0]
    },

    sellerAvatar () {
      return this.product?.avatar
    },

    sellerName () {
      return this.product?.full_name
    },

    productTitle () {
      return this.product?.title || ''
    },

    maxPrice () {
      return ((this.product?.max_price || 0) / 100).toFixed(2).replace('.00', '')
    },

    rubCurrencyRate () {
      return this.currencies?.find(c => c.code === 'RUB')?.exchange_rate || 0
    },

    maxPriceInRub () {
      return Math.ceil(this.product?.max_price / (this.rubCurrencyRate * 0.98) / 100)
    },

    isFavourite () {
      return this.product?.favourite
    },

    productDescription () {
      const productSeo = this.product?.seo_data?.[this.locale]

      if (productSeo?.h1) {
        return productSeo?.h1
      }

      if (this.product?.short_description && this.product?.short_description !== '') {
        return this.product?.short_description
      }

      return this.product?.description || ''
    },

    reviewsAmount () {
      return this.product?.reviews_amount || 0
    },

    rating () {
      return this.product?.rating || '-'
    },

    brand () {
      return this.product?.brand || ''
    },

    categoryName () {
      return this.product?.category || ''
    }
  },

  methods: {
    ...mapActions('modal', ['showModal']),

    openRegistrationModal () {
      if (!this.isAuthorized) {
        this.showModal({
          component: 'Auth',
          data: {
            mode: 'register'
          }
        })
      }
    },

    addToFavourites () {
      if (!this.isAuthorized) {
        this.openRegistrationModal()
      } else if (this.isFavourite) {
        StoreService.dropFavourite(this.$axios, this.productId)
        this.product.favourite = false
      } else {
        StoreService.addToFavourites(this.$axios, this.productId)
        this.product.favourite = true
      }
    }
  }
}
</script>

<template>
  <NuxtLink :class="[$style.product, $style.productBig]" :to="localeLocation(`/marketplace/product/${productId}`)">
    <div v-if="!short" :class="$style.product__cover">
      <nuxt-img
        :class="$style.product__coverImg"
        :src="productCover"
        :alt="productTitle"
        loading="lazy"
        height="200"
        width="242"
        format="webp"
      />
    </div>
    <div :class="$style.product__body">
      <div v-if="withSellerText" :class="$style.product__seller">
        <img :class="$style.product__sellerAvatar" :src="sellerAvatar" alt="avatar" />
        <span :class="$style.product__sellerName">
          {{ sellerName }}

          <span :class="$style.product__categoryNameLabel">
            {{ productTitle }}
          </span>
        </span>
      </div>
      <div v-if="withCategoryText" :class="$style.product__category">
        <img :class="$style.product__categoryAvatar" :src="productCover" alt="cover" />
        <span :class="$style.product__categoryName">
          {{ brand }}

          <span :class="$style.product__categoryNameLabel">
            {{ $t(categoryName) }}
          </span>
        </span>
      </div>

      <div :class="$style.product__price">
        <div :class="$style.product__dealerPrice">
          <Currency v-if="showRub" type="money" size="16" rub>
            <span :class="$style.product__dealerPrice">{{ maxPriceInRub }}</span>
          </Currency>
          <Currency v-else type="money" size="16" real>
            <span :class="$style.product__dealerPrice">{{ maxPrice }}</span>
          </Currency>
          <span :class="$style.separator">/</span>
          <Currency type="money" size="16">
            <span :class="$style.product__dealerPrice">{{ maxPrice }}</span>
          </Currency>
        </div>

        <div :class="$style.likeContainer" @click.stop.prevent="addToFavourites">
          <InlineSvg :class="{[$style.like]: true, [$style.likeActive]: isFavourite}" :src="require('~/assets/img/icons/heart.svg')" />
        </div>
      </div>

      <p :class="$style.product__text">
        {{ productDescription }}
      </p>

      <div :class="$style.product__bottom">
        <InlineSvg :class="$style.product__star" :src="require('~/assets/img/icons/star-gold.svg')" />
        <span :class="$style.product__rating">{{ rating }} {{ $t("rating") }}</span>
        <span :class="$style.product__reviews">/ {{ $t("nReviews", { amount: reviewsAmount }) }}</span>
      </div>
    </div>
  </NuxtLink>
</template>

<style lang="scss" module>
.product {
  display: block;
  background: $blue-grey70;
  color: $white;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;

  &Big {
    height: 100%;
    padding: 4px 4px 12px;
    overflow: hidden;
  }

  &__cover {
    width: 100%;
    height: 200px;
    margin-bottom: 8px;

    &Img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px 8px 0 0;
    }
  }

  &__body {
    padding: 0 8px;
  }

  &__seller {
    display: grid;
    grid-template-columns: 48px 1fr;
    grid-gap: 8px;
    align-items: center;
    padding: 4px;
    margin: 8px 0 16px;
    border-radius: 8px;
    background: rgba($blue-grey80, 0.3);

    &Avatar {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 50%;
    }

    &Name {
      color: $white;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;

      &Label {
        color: $blue-grey10;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }

  &__category {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-gap: 8px;
    align-items: center;
    padding: 4px;
    margin-bottom: 8px;
    background: $blue-grey80;

    &Avatar {
      width: 24px;
      height: 24px;
      object-fit: cover;
      border-radius: 4px;
    }

    &Name {
      color: $white;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;

      &Label {
        color: $blue-grey10;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }

  &__price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
  }

  &__text {
    margin: 8px 0;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: $white;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__star {
    width: 24px;
    height: 24px;
  }

  &__rating {
    color: $blue-grey05;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  &__reviews {
    color: $blue-grey10;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  &__description {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    color: $white;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid $blue-grey40;
  }

  &__dealer {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;

    &Right {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4px;

      &Top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px;
      }

      &Bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &Name {
      color: $white;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    &Avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }

    &Reviews {
      color: $blue-grey10;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }

    &Price {
      display: grid;
      grid-template-columns: max-content max-content max-content;
      grid-gap: 2px;
      align-items: center;
      color: $white;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      .separator {
        color: $blue-grey10;
      }
    }
  }
}

.likeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $blue-grey60;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  margin-left: 12px;
  cursor: pointer;
}

.like {
  height: 24px;
  width: 24px;

  &Active {
    path {
      fill: $primary60;
    }
  }
}
</style>
